<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex flex-row align-center">
        <v-btn text @click="()=>{this.$router.go(-1)}"><v-icon dark>mdi-arrow-left</v-icon></v-btn>
        <h1>{{plural}}</h1>
        <v-progress-circular
            indeterminate
            color="green"
            v-if="dataLoader"
            style="margin-left: 10px;"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <span class="d-flex flex-row align-center mb-2">
          <h3>Containers</h3>
          <v-btn class="ml-2" small fab color="info" @click="createNewContainer"><v-icon>mdi-plus</v-icon></v-btn>
        </span>
        <div v-for="(container, index) in data" :key="index">
          <v-card outlined class="mb-1">
            <v-card-title class="d-flex flex-row align-start">
              <span class="d-flex flex-row justify-space-between align-start">
                <div>ID: {{ container.id }}</div>
                <span class="d-flex flex-column justify-start align-start">
                  <v-text-field class="ml-2" dense outlined label="Name" v-model="container.name"/>
                  <span class="d-flex flex-row" style="margin-top: -20px;">
                    <v-checkbox class="mr-4" label="Card" v-model="container.metadata.card"/>
                    <v-checkbox label="Outlined" v-model="container.metadata.outlined"/>
                  </span>
                </span>
              </span>
              <span class="ml-2">
                <v-btn v-if="isCreate" @click="saveNewContainer(index)" fab x-small color="success"><v-icon>mdi-content-save</v-icon></v-btn>
                <v-btn v-else @click="updateContainer(index)" fab x-small color="success"><v-icon>mdi-content-save</v-icon></v-btn>
                <cab class="ml-2" fab fabIcon="mdi-close" xsmall color="error" @cb="deleteContainer(index)" :dialogText="'This will delete all Dynamic Buttons associated with this Container. Are you sure you wish to proceed?'" />
                <v-btn class="ml-2" @click="selectContainer(index)" fab x-small color="info"><v-icon>mdi-chevron-right</v-icon></v-btn>
              </span>
            </v-card-title>
          </v-card>
        </div>
      </v-col>
      <v-col>
        <span class="d-flex flex-column">
          <span class="d-flex flex-row align-center mb-3">
            <h3 class="mr-3">Dynamic Buttons</h3>
            <v-btn x-small fab color="info" @click="createNewDynamicButton"><v-icon>mdi-plus</v-icon></v-btn>
          </span>
          <span v-if="form.dynamicButtons">
            <div v-for="(db, index) in form.dynamicButtons.data" :key="index">
              <v-card outlined class="mb-2">
                <v-card-title class="d-flex flex-row justify-space-between align-start">
                  <span class="d-flex flex-row">
                    <div class="mr-2">{{db.name}}</div>
                    <!-- <p style="font-size: 10px;">{{ db }}</p> -->
                  </span>
                  <div class="d-flex flex-row align-start">
                    <v-text-field class="mr-2 " type="number" dense outlined label="Preview ID" v-model="previewDocumentIds[index]"/>
                    <v-btn class="mr-1" :disabled="!previewDocumentIds[index]" @click="previewDB(index)" fab x-small color="warning"><v-icon>mdi-printer-eye</v-icon></v-btn>
                    <v-btn class="mr-1" @click="updateData" fab x-small color="success"><v-icon>mdi-content-save</v-icon></v-btn>
                    <cab fab fabIcon="mdi-close" xsmall color="error" @cb="deleteDB(index)" :dialogText="'Are you sure you wish to proceed?'" />
                  </div>
                </v-card-title>
                <v-card-text>
                  <span class="d-flex flex-row">
                    <v-text-field class="mr-2" dense outlined label="Dynamic Button Name" v-model="db.name"/>
                    <v-text-field class="mr-2" dense outlined label="Dynamic Button Classes" v-model="db.classes"/>
                    <v-text-field dense outlined label="Document Template" hint="This needs to be the exact name as the document template for features to work" v-model="db.documentTemplate"/>
                  </span>
                  <span class="d-flex flex-row">
                    <v-text-field class="mr-2" dense outlined label="Description" v-model="db.description"/>
                  </span>
                  <span class="d-flex flex-row">
                    <v-checkbox label="FAB" v-model="db.fab"/>
                    <v-text-field class="ml-2" dense outlined label="FAB Icon" v-model="db.fabIcon"/>
                    <v-text-field class="ml-2" dense outlined label="Dynamic Button Color" v-model="db.color"/>
                    <v-text-field class="ml-2" dense outlined label="Dynamic Button Size" v-model="db.size"/>
                  </span>
                  <span class="d-flex flex-row">
                    <v-checkbox label="Require Confirm" v-model="db.requireConfirm"/>
                    <v-checkbox class="ml-2" label="Require Password" v-model="db.requireConfirmRequirePassword"/>
                    <v-checkbox class="ml-2" label="Require Reason" v-model="db.requireConfirmRequireReason"/>
                    <v-text-field class="ml-2" dense outlined label="Dialog Text" v-model="db.requireConfirmDialogText"/>
                  </span>
                  <span class="d-flex flex-row">
                    <v-select
                      :items="requestTypes"
                      outlined
                      dense
                      label="Request Type"
                      v-model="db.requestType"
                    ></v-select>
                    <v-text-field class="ml-2" dense outlined label="Endpoint" v-model="db.requestEndpoint"/>
                    <v-checkbox label="$route.params.id" v-model="db.requestAppendRouteParamsId"/>
                  </span>
                  <span class="d-flex flex-column">
                    <hr>
                    <h3 class="d-flex flex-row align-center my-2">
                      <b>Disabled if...</b>
                      <v-btn class="ml-2" x-small fab color="info" @click="createNewDynamicButtonDisabledRule(db)"><v-icon>mdi-plus</v-icon></v-btn>
                    </h3>
                    <div v-for="(rule, index2) in db.disabled" :key="index2">
                      <span class="d-flex flex-row">
                        <v-select
                          :items="concatOpTypes"
                          outlined
                          dense
                          label="Concat Op"
                          v-model="rule.concatOp"
                        ></v-select>
                        <v-select
                          class="ml-2"
                          :items="ifTypes"
                          outlined
                          dense
                          label="if"
                          v-model="rule.if"
                        ></v-select>
                        <v-select
                          :items="isTypes"
                          class="ml-2"
                          outlined
                          dense
                          label="is"
                          v-model="rule.is"
                        ></v-select>
                        <v-text-field class="ml-2" dense outlined label="Value" v-model="rule.value"/>
                        <v-btn class="ml-2" x-small fab color="error" @click="deleteDynamicButtonDisabledRule(db, index2)"><v-icon>mdi-close</v-icon></v-btn>
                      </span>
                    </div>
                  </span>
                </v-card-text>
              </v-card>
            </div>
          </span>
        </span>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from "axios";
import cab from '../../components/confirmedActionButton.vue'
export default {
  name: 'Home',
  components: {
    cab
  },
  data () {
    return {
      printHandlerLoading: false,
      dataLoader: false,
      formLoader: false,
      requestTypes: ['get', 'post', 'put', 'delete'],
      concatOpTypes: ['NONE','AND', 'OR'],
      ifTypes: ['STATUS'],
      isTypes: ['EQ', 'LT', 'LTE', 'GT', 'GTE', 'NEQ', 'EQS'],
      newDynamicButtonDialog: {
        isOpen: false,
        isLoading: false,
        data: {
          name: '',
          color: '',
          size: '',
          classes: '',
          icon: false,
        }

      },
      snackObj: {
        state: false,
        color: '',
        text: ''
      },

      isEditable: false,
      isCreate: false,

      singular: "Dynamic Button Container",
      singularLower: "dynamicbuttoncontainer",
      plural: "Dynamic Button Containers",
      pluralLower: "dynamicbuttoncontainers",

      requiredRules: [
        v => !!v || 'Field is required.'
      ],

      data: [],
      form: {},
      validForm: false,

      headers: [
        { text: 'ID', align: 'start', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Dynamic Buttons', value: 'dynamicButtons' },
        { text: 'Metadata', value: 'metadata' }
      ],
      newDynamicButton: {},
      previewDocumentIds: [],
    }
  },
  computed: {
    ...mapGetters(['getEndpoint'])
  },
  async mounted(){
    try { 
      this.dataLoader = true;

      await this.fetchData();

    } catch (error) {
      console.error(error)
      this.snack(error.msg || error.msg?.message || error, "error");
    }finally {
      this.dataLoader = false;
    }
  },
  methods: {
    async previewDB(index){
      try {
        this.dataLoader = true
        if(this.previewDocumentIds[index] && this.form.dynamicButtons.data[index] && this.form.dynamicButtons.data[index].requestType && this.form.dynamicButtons.data[index].requestEndpoint){
          let obj = {
            method: this.form.dynamicButtons.data[index].requestType,
            action: this.form.dynamicButtons.data[index].requestEndpoint,
            id: this.previewDocumentIds[index]
          }
          console.log(obj)
          await this.printHandler(obj)
        }
      }catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      } finally {
        this.dataLoader = false
      }
    },
    async printHandler(obj){ // {id, method, action}
      try {
        this.printHandlerLoading = true
        let x = await axios[obj.method](`${this.getEndpoint}/${obj.action}/${obj.id}`)
        if(x.data.error) throw x.data.error
        
        let printWindow = open("","Printing")
        printWindow.document.write("")
        printWindow.document.write(x.data.data.job.htmlToPrint)
        
        printWindow.setTimeout(()=>{
          printWindow.print()
          printWindow.document.write("")
        },500)

      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error")
      } finally {
        this.printHandlerLoading = false
      }
    },
    createNewContainer(){
      this.data.splice(0,0,{
        name: 'New Container',
        metadata: {
          card: false,
          outlined: false
        }
      })
      this.isCreate = true
    },
    createNewDynamicButton(){
      this.form.dynamicButtons.data.splice(0,0,{
        name: 'New Dynamic Button..',
        color: '',
        size: '',
        classes: '',
        fabIcon: '',
        fab: false,
        disabled: []
      })
    },
    createNewDynamicButtonDisabledRule(db){
      if(!db.disabled){
        db.disabled = []
      }

      db.disabled.push({
        concatOp: (db.disabled.length == 0?'NONE':""),
        if: '',
        is: '',
        value: '',
      })
      this.$forceUpdate()
    },
    deleteDynamicButtonDisabledRule(db, index){
      console.log(db,index)
      db.disabled.splice(index,1)
    },
    async saveNewContainer(index){
      try{
        this.dataLoader = true;

        if(this.data[index].name){
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}`, {
            name: this.data[index].name,
            card: this.data[index].card,
            outlined: this.data[index].outlined,
            dynamicButtons: {data: []}
          })
          if(res.data.error) throw res.data.error
          this.snack("Container created.", "success");
        }
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.dataLoader = false;
      }
    },
    async updateContainer(index){
      try{
        this.dataLoader = true;

        if(this.data[index].name){
            let obj = {
              card: this.data[index].metadata.card,
              outlined: this.data[index].metadata.outlined
            }
           let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${this.data[index].id}`, {name: this.data[index].name, metadata: obj})
          if(res.data.error) throw res.data.error
          this.snack("Name Updated", "success");
        }
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.dataLoader = false;
      }
    },
    async deleteContainer(index){
      try{
        this.dataLoader = true;

        if(this.data[index].id){
          let res = await axios.delete(`${this.getEndpoint}/api/${this.pluralLower}/${this.data[index].id}`)
          if(res.data.error) throw res.data.error

          this.data.splice(index,1)

          this.snack("Container Deleted", "success");  
        }
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.dataLoader = false;
      }
    },
    async deleteDB(index){
      try {
        this.form.dynamicButtons.data.splice(index,1)
        await this.updateData()
      }catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
    },
    newForm(){
      this.form = {
        name: '',
        description: '',
        metadata: ''
      }
      this.isEditable = true;
      this.isCreate = true;
    },
    selectContainer(index){
      console.log(this.data, index)
      if(this.data[index].id){
        this.form = this.data[index];
      }
    },
    async cancelForm(){
      try{
        this.formLoader = true;

        await this.fetchData();

        this.form = this.data.find(x=>x.id===this.form.id);

        //custom form data changes here
        this.form.metadata = JSON.stringify(this.form.metadata)
        this.form.dynamicButtons = JSON.stringify(this.form.dynamicButtons)

        this.isEditable = false;
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally
      {
        this.formLoader = false;
      }
    },
    async fetchData(){
      try{
        this.dataLoader = true;

        let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}`)
        if(res.data.error) throw res.data.error
        this.data = res.data.data
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.dataLoader = false;
      }
    },
    async createData(){
      try{
        this.formLoader = true;

        //custom form data changes here
        let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}`, this.form)
        if(res.data.error) throw res.data.error
        this.snack(`${this.singular} created!`, "success");

        await this.fetchData();
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.formLoader = false;
      }
    },
    async updateData(){
      try{
        this.formLoader = true;

        let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${this.form.id}`, this.form)
        if(res.data.error) throw res.data.error

        this.snack(`${this.singular} updated!`, "success");
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.formLoader = false;
      }
    },
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    formatDate(d, type="short"){
      if(!d) return "None";
      if(type=="short")
        return (new Date(d)).toLocaleDateString('en-GB')
      if(type=="long")
        return (new Date(d)).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    }
  }
}
</script>
